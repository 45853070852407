
.s-title, .navbar-brand {
  font-family: "Permanent Marker", cursive;
  font-weight: bold;
  font-size: 28px;
}

.App, .navbar {
  background-color: #F5DD61;
  font-family: "Geologica", sans-serif;
}

.sh {
  font-family: "Permanent Marker", cursive;
}

.border-header {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

.nav-link:hover {
  border: 1px solid black;
}

.sec-header {
  font-weight: bold;
}

.card-body-2 {
  background-color: #FFEDD8;
  border: 1px solid black;
  border-radius: 0.4rem;
}

.card-body-2:hover {
  box-shadow: 0.6rem 0.6rem 0 #222;

}

.card-border {
  background-color: #FFEDD8;
  border: 1px solid black;
  border-radius: 0.4rem;
  box-shadow: 0.6rem 0.6rem 0 #222;

}

iframe {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.hero-text {
  font-size: 2rem;
}

.socmed {
  padding: 0;
}

.socmed li{
  display: inline-block;
  margin-right: 1.2rem;
}

.bg-footer {
  background-color: #F4538A;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

.airdrop-btn {
  margin-top: 5px;
  margin-left: 13px;
}